import { useState, useRef, useEffect, FC } from 'react';
import {
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  Textarea,
  Box,
  Spinner
} from '@chakra-ui/react';
import { DownloadIcon } from './assets';
import { InfoIcon } from 'assets';
import { Dropzone, TooltipLabel } from './components';
import { getMaxMerfishGenesAmount, useCreatePanelStore } from 'store';
import { Link as BrowserLink } from 'react-router-dom';
import {
  SingleAccordion,
  theme,
  Tooltip,
  useFailureNotification,
  formatErrorMessages
} from '@vizgen/vizgen-ui';
const { colors } = theme;

export const CustomInput: FC = () => {
  const [isDropzoneVisible, setIsDropzoneVisible] = useState(true);
  const [genes, setGenes] = useState('');
  const textarea = useRef<HTMLTextAreaElement>(null);
  const uploadTranscripts = useCreatePanelStore(
    (state) => state.uploadTranscripts
  );
  const isUploadingGenes = useCreatePanelStore(
    (state) => state.isUploadingGenes
  );
  const merfishVersion = useCreatePanelStore(
    (state) => state.panel?.merfishVersion
  );
  const { showFailureNotification, closeFailureNotifications } =
    useFailureNotification();

  const onGenesChanged = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGenes(e.target.value);
  };

  const addGenes = async () => {
    closeFailureNotifications();
    try {
      const isSucceed = await uploadTranscripts(genes);
      if (isSucceed) {
        clearAll();
      }
    } catch (e) {
      showFailureNotification(formatErrorMessages(e));
    }
  };

  const clearAll = () => {
    setGenes('');
    setIsDropzoneVisible(true);
  };

  const onBlur = () => {
    if (!genes) {
      setIsDropzoneVisible(true);
    }
  };

  const setFocus = () => {
    textarea?.current?.focus();
  };

  useEffect(() => {
    if (!isDropzoneVisible) {
      setFocus();
    }
  }, [isDropzoneVisible]);

  return (
    <SingleAccordion title="Custom Input">
      <Flex alignItems="flex-end" justify="space-between">
        <Stack spacing={0} mb="8px" ml="12px">
          <Text>Up to {getMaxMerfishGenesAmount(merfishVersion)} genes</Text>
          <Text>Use gene names or Ensembl IDs</Text>
        </Stack>
        {genes && (
          <Button
            mb="4px"
            size="tiny"
            variant="tinyGhostSecondary"
            onClick={clearAll}
            data-testid="clearAllCustomGenesButton"
          >
            Clear All
          </Button>
        )}
      </Flex>
      {isUploadingGenes ? (
        <HStack
          h="96px"
          spacing="8px"
          borderRadius="8px"
          background="background.primary"
          justify="center"
          border="1px solid"
          borderColor="border.normal"
        >
          <Spinner color="content.additional" />
          <Text color="content.additional">Verifying genes...</Text>
        </HStack>
      ) : (
        <Box h="96px">
          {isDropzoneVisible ? (
            <Dropzone
              setIsDropzoneVisible={setIsDropzoneVisible}
              setGenes={setGenes}
            />
          ) : (
            <Textarea
              resize="none"
              ref={textarea}
              name="customInputTextarea"
              value={genes}
              onChange={onGenesChanged}
              onBlur={onBlur}
              borderRadius="8px"
              h="96px"
              placeholder="Type genes on separate lines"
            />
          )}
        </Box>
      )}
      <HStack spacing="4px" m="8px 0 16px 8px">
        <Tooltip w="380px" label={<TooltipLabel />}>
          <Box
            cursor="pointer"
            css={{
              '&:hover path': {
                stroke: colors.content.link.normal
              },
              '&:hover path.icon-path_filled': {
                stroke: 'transparent',
                fill: colors.content.link.normal
              }
            }}
          >
            <InfoIcon />
          </Box>
        </Tooltip>
        <Text color="content.additional">Add notes and abundance</Text>
      </HStack>
      <Flex justify="space-between" alignItems="center">
        <Button
          size="tiny"
          variant="tinyGhostSecondary"
          as={BrowserLink}
          target="_blank"
          to="/files/gene_list_example.csv"
          download
          data-testid="downloadCSVExampleButton"
        >
          <HStack spacing="4px" as="span">
            <DownloadIcon />
            <Text color="content.link.normal">Get .csv file example</Text>
          </HStack>
        </Button>
        <Button
          variant="secondaryFilled"
          minW="156px"
          onClick={addGenes}
          isDisabled={isUploadingGenes}
          data-testid="addGenesButton"
        >
          Add Genes
        </Button>
      </Flex>
    </SingleAccordion>
  );
};
