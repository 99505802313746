import { FC, useState } from 'react';
import { Heading, Button, Flex, Text, Stack } from '@chakra-ui/react';
import { ContainerLayout, Navigation } from 'components';
import { useGetPanel } from 'hooks';
import { generatePath, useNavigate, useParams } from 'react-router';
import {
  PanelFormData,
  useCreatePanelStore,
  useFeatureFlagsStore
} from 'store';
import { useForm } from 'react-hook-form';
import {
  CommentField,
  PanelField,
  PanelNameField,
  ReferenceField,
  TissueField,
  PanelSummary,
  ConfirmLeavingModal,
  ChemistryVersion
} from './components';
import {
  useGetPanelData,
  useSetSourcePanel,
  useSetInitialValues,
  useFormState,
  FormState
} from './hooks';
import { Paths } from 'routes';
import { Header } from '../Header';
import { useCustomPrompt } from 'hooks';
import { shallow } from 'zustand/shallow';

export const ConfigurationStep: FC = () => {
  const { watch, control, handleSubmit, setValue } = useForm();
  useGetPanel();
  const { panelId } = useParams<{ panelId: string }>();
  const [addGenesFromSourcePanel, createPanel, isPanelUpdating, updatePanel] =
    useCreatePanelStore(
      (state) => [
        state.addGenesFromSourcePanel,
        state.createPanel,
        state.isPanelUpdating,
        state.updatePanel
      ],
      shallow
    );
  const navigate = useNavigate();
  const formData: PanelFormData = watch();
  const isSourcePanelSelected =
    !!formData?.sourcePanel?.panelId &&
    formData?.sourcePanel?.panelId !== 'custom';

  const { initialFormData, isFetchingPanelData } = useGetPanelData();
  const [isAfterSave, setIsAfterSave] = useState(false);
  const isDisabled = isPanelUpdating || isFetchingPanelData || isAfterSave;
  useSetInitialValues(initialFormData, setValue);
  useSetSourcePanel(formData, setValue);
  const formState = useFormState(formData);
  const { gm201 } = useFeatureFlagsStore((state) => state.featureFlags);
  const { isOpen, onConfirm, onCancel } = useCustomPrompt(!!formState);

  const submitForm = async (form: PanelFormData) => {
    let id = panelId;
    if (id) {
      if (formState !== FormState.Valid) {
        await updatePanel(form);
      }
    } else {
      id = await createPanel(form);
      await addGenesFromSourcePanel(form.sourcePanel || null, id);
    }
    setIsAfterSave(true);

    // setTimeout to run navigate after state update
    // to prevent navigation blocking by useCustomPrompt
    setTimeout(() =>
      navigate(generatePath(Paths.CreatePanel_AddGenes, { panelId: id }))
    );
  };

  return (
    <>
      <Header currentStep={0} />
      <Flex
        as="form"
        h="100%"
        flexDir="column"
        onSubmit={handleSubmit(submitForm)}
      >
        <ContainerLayout>
          {panelId && formData ? (
            <PanelSummary data={formData} />
          ) : (
            <Text>New Panel</Text>
          )}
          <Heading as="h1" pb="32px">
            Configure Panel
          </Heading>
          <Stack textAlign="left" spacing="36px">
            {!panelId && (
              <PanelField
                control={control}
                sourcePanel={initialFormData?.sourcePanel || null}
                isDisabled={isDisabled}
              />
            )}
            {gm201 && (
              <ChemistryVersion
                control={control}
                isDisabled={isDisabled}
                merfishVersion={initialFormData?.merfishVersion || null}
              />
            )}
            <Stack spacing="8px">
              <PanelNameField
                control={control}
                panelName={initialFormData?.panelName || ''}
                isDisabled={isDisabled}
              />
              {/* Temporarily hidden VCD-352 */}
              {/* <ProprietaryField
                control={control}
                isProprietary={initialFormData?.isProprietary ?? true}
                isDisabled={isDisabled || !!panelId}
              /> */}
            </Stack>
            <ReferenceField
              control={control}
              isDisabled={isSourcePanelSelected || isDisabled || !!panelId}
              transcript={initialFormData?.reference || null}
            />
            <TissueField
              control={control}
              referenceValue={formData.reference?.transcriptomeId}
              isDisabled={isSourcePanelSelected || isDisabled || !!panelId}
              tissueId={
                formData.sourcePanel?.tissueId ||
                initialFormData?.tissue?.tissueId ||
                null
              }
            />
            <CommentField
              control={control}
              comment={initialFormData?.comment || ''}
              isDisabled={isDisabled}
            />
          </Stack>
        </ContainerLayout>
        <Navigation
          rightButton={
            <Button
              type="submit"
              data-testid="nextButton"
              variant="secondarySolid"
              px="5"
              isDisabled={isDisabled}
            >
              Next
            </Button>
          }
        />
        {isOpen && (
          <ConfirmLeavingModal
            state={formState}
            formData={formData}
            sourcePanel={formData.sourcePanel || null}
            onCancel={onCancel}
            onConfirm={onConfirm}
          />
        )}
      </Flex>
    </>
  );
};
