import { FC, useEffect } from 'react';
import { HStack, Text, Flex, Box } from '@chakra-ui/react';
import {
  DefaultItemType,
  Dropdown,
  ValidationMessage
} from '@vizgen/vizgen-ui';
import { Control, FieldValues, useController } from 'react-hook-form';

interface ChemistryVersionProps {
  control: Control<FieldValues>;
  isDisabled: boolean;
  merfishVersion: DefaultItemType | null;
}

export const MERFISH_VERSIONS: DefaultItemType[] = [
  {
    label: 'MERFISH 1.0',
    value: '1'
  },
  {
    label: 'MERFISH 2.0',
    value: '2'
  }
];

export const ChemistryVersion: FC<ChemistryVersionProps> = ({
  control,
  isDisabled,
  merfishVersion
}) => {
  const {
    field: { onChange, name, value },
    fieldState: { invalid }
  } = useController({
    name: 'merfishVersion',
    control,
    rules: { required: true },
    defaultValue: merfishVersion
  });

  const onChangeHandler = (e: DefaultItemType | null) => {
    onChange(e);
  };

  useEffect(() => {
    if (merfishVersion) {
      onChange(merfishVersion);
    }
  }, [merfishVersion]); // eslint-disable-line

  return (
    <HStack spacing="24px" alignItems="flex-start">
      <Flex direction="column" maxW={264} w="40%" pt="12px">
        <Flex direction="row">
          <Text mr="8px">Chemistry version</Text>
        </Flex>
        <Text color="content.additional" variant="captionShort">
          MERFISH 1.0 and MERFISH 2.0 chemistry versions require different panel
          designs and reagents. For more information contact Vizgen technical
          support.
        </Text>
      </Flex>
      <Box maxW={360} w="60%" pos="relative">
        <Dropdown
          data-testid="select-chemistry-version"
          items={MERFISH_VERSIONS}
          defaultValue={value}
          value={value}
          onChange={onChangeHandler}
          valueKey="value"
          labelKey="label"
          name={name}
          isDisabled={isDisabled}
          placeholder="Choose chemistry version"
          isInvalid={invalid}
        />
        {invalid && (
          <ValidationMessage>Fill the required field</ValidationMessage>
        )}
      </Box>
    </HStack>
  );
};
