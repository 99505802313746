import { HStack, StackDivider, VStack } from '@chakra-ui/react';
import { PanelTranscriptStatus } from 'generated/types';
import { FC, Fragment } from 'react';
import {
  duplicatedGeneNamesSelector,
  transcriptsByStatusAmountSelector,
  useCreatePanelStore,
  useFeatureFlagsStore
} from 'store';
import { Detail, DuplicatedGenesDetail, MaxGenesIssue } from './components';

const renderRows = (rows: (JSX.Element | null)[]) => (
  <HStack
    alignItems="center"
    pl="16px"
    spacing="16px"
    minH="32px"
    divider={<StackDivider borderColor="border.divider" />}
  >
    {rows.map((component, i) => (
      <Fragment key={i}>{component}</Fragment>
    ))}
  </HStack>
);

export const DetailInfo: FC = () => {
  const { gm201 } = useFeatureFlagsStore((state) => state.featureFlags);
  const { insufficient_tr, untargetable, not_found } = PanelTranscriptStatus;
  const notFoundAmount = useCreatePanelStore(
    transcriptsByStatusAmountSelector(not_found)
  );
  const nonTrackableAmount = useCreatePanelStore(
    transcriptsByStatusAmountSelector(untargetable)
  );
  const insufficientAmount = useCreatePanelStore(
    transcriptsByStatusAmountSelector(insufficient_tr)
  );
  const duplicatedAmount = useCreatePanelStore(
    duplicatedGeneNamesSelector
  ).length;
  const components = [
    <Detail status={insufficient_tr} amount={insufficientAmount} />,
    <Detail status={untargetable} amount={nonTrackableAmount} />,
    <Detail status={not_found} amount={notFoundAmount} />,
    <DuplicatedGenesDetail amount={duplicatedAmount} />,
    gm201 ? <MaxGenesIssue /> : null
  ].filter((component) => component);
  const firstRow = components.slice(0, 3);
  const secondRow = components.slice(3);

  return (
    <VStack spacing="8px" align="start">
      {renderRows(firstRow)}
      {!!secondRow.length && renderRows(secondRow)}
    </VStack>
  );
};
